import React from "react";

// This file is used at : [ backend.js ]

const EnhanceComponent = (props) => {
  const {
    enhanceTitle,
    enhanceDescription,
    enhanceImage,
    enhanceDescriptionText,
  } = props;
  return (
    <div className="enhance-section">
      <div className="full-container">
        <div className="row">
          <div className="col-md-6">
            <p className="enhance-section-title ">{enhanceTitle}</p>
            <p
              className="enhance-section-text "
              dangerouslySetInnerHTML={{ __html: enhanceDescription }}
            ></p>
            <p
              className="enhance-section-text "
              dangerouslySetInnerHTML={{ __html: enhanceDescriptionText }}
            ></p>
          </div>
          <div className="col-md-6 mvp-section-wrapper">
            <img
              src={enhanceImage}
              alt="enhanceImage"
              className="enhance-section-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default EnhanceComponent;
